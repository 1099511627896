<template>
  <div class="mt-1">
    <b-row v-if="formLoaded">
      <b-col align="right">
        <b-button
          type="button"
          variant="primary"
          size="sm"
          @click="selectCreateTask(2, 'registries')"
          title="Crea per Anagrafica"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Crea per
          Anagrafica</b-button
        >
        <b-button
          type="button"
          variant="primary"
          size="sm"
          @click="selectCreateTask(2, 'insurance_policies')"
          title="Crea per Polizza"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Crea per
          Polizza</b-button
        >
      </b-col>
    </b-row>
    <tasks
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byRegistry', 'byInsurancePolicy'],
      }"
      noActions
      noInnerWidth
      noInputPaginator
    >
    </tasks>

    <div v-if="formLoaded">
      <event-create-modal
        v-model="evt"
        :beFormCreate="beCreateForm['task']"
        :beRulesCreate="beRules['task']"
        @cancel="cancelEventCreation"
        @create="storeEvent"
        @addAppointment="onAddAppointment"
      ></event-create-modal>
    </div>
  </div>
</template>

<script>
import Tasks from "@/components/tables/Tasks";
import FormMixin from "@/mixins/FormMixin";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import {
  hasBookEntry,
  hasInsuranceAncillary,
  getVirtualValue,
} from "@/utils/payments";

export default {
  mixins: [
    FormMixin,
    CalendarEventsAndTasksMixin,
    DetailTabAutofetchTableMixin,
  ],
  name: "Notes",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filter: this.initFilter(),
      details: null,
      POLICY_ID: null,
      REGISTRY_ID: null,
      TASK_IDS: [0],
      // registry: null,
      repository: "task",
      resource: "tasks",
      formLoaded: false,
      filterName: "filterInsurancePaymentTasks",
      tableRef: "insurancePaymentTaskTableRef",
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "task"),
        },
        {
          key: "description",
          label: this.getDictionary("description", "task"),
        },
        {
          label: this.getDictionary("task_type", "task"),
          key: "task_type.text",
        },
        {
          label: this.getDictionary("is_public", "task"),
          key: "is_public.text",
        },
        {
          label: this.getDictionary("users", "task"),
          key: "users",
        },
        {
          key: "entity",
          label: this.getDictionary("Entità"),
        },
      ],
      taskable_id: null,
      taskable: null,
      beCreateForm: {}, // define this copy for co-existende of create an edit inside the same component
      // beEditForm: {}, // define this copy for co-existende of create an edit inside the same component
      form: {
        task: {
          id: null,
          title: null,
          description: null,
          task_type: null,
          status_task: null,
          users: null,
          owner_id: null,
          taskable: this.resourceId, //nome della relazione
          taskable_id: null, //array di ID di modelli (verosimilmente sempre 1 nelle ns. GUI)
          start: null, // date
          end: null, // date
          startTime: null,
          endTime: null,
          expired_at: null,
        },
      },
    };
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: { id: this.TASK_IDS },
      };
      return init;
    },
    selectCreateTask(task_type, resource) {
      switch (resource) {
        case "registries":
          // nota
          this.taskable_id = this.REGISTRY_ID;
          this.taskable = "registry";
          break;
        case "insurance_policies":
          this.taskable_id = this.POLICY_ID;
          this.taskable = "insurance_policy";
          break;
      }
      this.initDefaultFormValues({ task_type });
      this.createTask(task_type);
    },
  },
  components: {
    Tasks,
  },

  beforeMount() {
    this.initDefaultFormValues({ task_type: 2 });
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.beCreateForm[this.repository] = this.convertDataToCalendarEvents([
          this.beForm[this.repository],
        ])[0];
        this.formLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
    let hasInsurancePolicy;
    switch (true) {
      case hasBookEntry(this.dbRowData):
        this.details = this.dbRowData.payable;
        this.REGISTRY_ID = this.details.registries[0].id;
        hasInsurancePolicy = this.dbRowData.payable.insurance_ancillaries.length
          ? true
          : false;
        if (hasInsurancePolicy) {
          this.POLICY_ID =
            this.dbRowData.payable.insurance_ancillaries[0].insurance_policy.id;
        }
        break;
      case hasInsuranceAncillary(this.dbRowData):
        this.details = this.dbRowData.payable;
        this.REGISTRY_ID = this.details.insurance_policy.registry.id;
        hasInsurancePolicy = this.dbRowData.payable.insurance_policy
          ? true
          : false;
        if (hasInsurancePolicy) {
          this.POLICY_ID = this.dbRowData.payable.insurance_policy.id;
        }
        break;
    }
    // WARNING:
    // Il fatto è che qui si vuol mostrare il tab NOTE contenente le note collegate al Quietanzamento,
    // che possono essere taskable su Registry e Policy, cioè la UNION di entrambi.
    // => nonostante siano disponibili sia REGISTRY_ID che POLICY_ID, NON posso applicare i 2 filtri:
    // 1. byRegistry[id]
    // 2. byInsurancePolicy[id]
    // perché operano in AND, ma qui servirebbe estrarre in OR..
    // Per questo motivo, in questa Notes "particolare", occorre filtrare i task byAttribute[id]=a,b,c,...
    // con gli id che sono stati estratti a monte dalla index del Quietanzamento e resi disponibili in rawData
    // Il giro è lungo e contorto (omissis..), ma l'unico modo di estrarre i task "giusti" è usare TASK_IDS,
    // aggiornandolo in caso vengano create nuove note.
    // Un effetto collaterale è che aprendo invece il tab NOTE dai dettagli dei tab Anagrafica/Polizze, dopo la creazione di una nota,
    // non c'è modo di aggiornare TASK_IDS, poichè rawData è creato in altro contesto (index del Q)
    let t = getVirtualValue("info", this.dbRowData).notes.rawData;
    this.TASK_IDS = t.map((e) => e.id);
    console.debug("this.TASK_IDS", this.TASK_IDS);
    this.$set(
      this.filter.byAttribute,
      "id",
      this.TASK_IDS.length ? this.TASK_IDS : [0]
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-md-4.border-bottom {
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
