<template>
  <div class="mt-1">
    <policies
      v-if="hasInsurancePolicy"
      :fields="fields"
      @edit="onEdit"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal', 'edit']"
      noInnerWidth
      noInputPaginator
      noPagination
    >
    </policies>
    <p v-if="!hasInsurancePolicy">Polizza non presente</p>
  </div>
</template>

<script>
import Policies from "@/components/tables/Policies";
import FormMixin from "@/mixins/FormMixin";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { hasBookEntry, hasInsuranceAncillary } from "@/utils/payments";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin, DetailTabAutofetchTableMixin],
  name: "Policy",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Policies,
  },
  data() {
    return {
      filter: this.initFilter(),
      details: null,
      POLICY_ID: null,
      policy: null,
      hasInsurancePolicy: null,
      repository: "insurance_policy",
      resource: "insurance_policies",
      filterName: "filterInsurancePaymentPolicy",
      tableRef: "insurancePaymentPolicyTableRef",
      fields: [
        {
          label: this.getDictionary("insurer"),
          key: "compagnia",
        },
        {
          label: this.getDictionary("insurance_policy"),
          key: "number",
        },
        {
          label: this.getDictionary("risk_branch"),
          key: "branch_risk",
        },
        {
          label: this.getDictionary("contractor"),
          key: "contraente",
        },
        {
          label: this.getDictionary("covered_at"),
          key: "covered_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          label: this.getDictionary("expired_at"),
          key: "expired_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          label: this.getDictionary("suspended_at", "insurance_policy"),
          key: "suspended_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          label: this.getDictionary("status_policy", "insurance_payment"),
          key: "status_policy.text",
        },
        {
          label: this.getDictionary("payment_splitting", "insurance_policy"),
          key: "payment_splitting.text",
        },
        {
          label: this.getDictionary("salesman"),
          key: "broker",
        },
        {
          label: this.getDictionary("cooperator", "insurance_payment"),
          key: "collaborazione",
        },
        {
          label: this.getDictionary("gross_premium", "insurance_payment"),
          key: "gross_premium",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: { id: this.POLICY_ID },
      };
      return init;
    },
    onEdit(id, item) {
      // GLITCH BACK: senza abilitare questo codice, la breadcrumb mantiene Quitanzamento Ricerca come level: 2,
      // fornendo involontariamente il "torna indietro"
      // abilitandolo, la breadcrumb è fixata

      // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
      this.emptyQueue();
      this.notifyQueue({
        level: 0,
        path: "",
        text: "Portafoglio",
      });
      this.notifyQueue({
        level: 1,
        path: "module.PFOLIO",
        text: "Gestione Portafoglio",
      });
      this.notifyQueue({
        level: 2,
        path: "module.PFOLIO",
        text: "Polizze",
      });

      // TODO SWITCH $router push/replace when UNCOMMENT
      this.$router.replace({
        name: "insurance_policies.edit",
        params: { id: `${id}`, item: item },
      });
    },
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
  },
  mounted() {
    this.isLoading = true;
    switch (true) {
      case hasBookEntry(this.dbRowData):
        this.hasInsurancePolicy = this.dbRowData.payable.insurance_ancillaries
          .length
          ? true
          : false;
        if (this.hasInsurancePolicy) {
          this.policy =
            this.dbRowData.payable.insurance_ancillaries[0].insurance_policy;
        }
        break;

      case hasInsuranceAncillary(this.dbRowData):
        this.hasInsurancePolicy = this.dbRowData.payable.insurance_policy
          ? true
          : false;
        if (this.hasInsurancePolicy) {
          this.policy = this.dbRowData.payable.insurance_policy;
        }
        break;
    }
    this.POLICY_ID = this.policy ? this.policy.id : null;
    this.$set(this.filter.byAttribute, "id", this.POLICY_ID);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-md-4.border-bottom {
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
