<template>
  <b-modal
    id="quickSearchAncillaryModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-text"></b-icon>
        <span>Cerca Titoli</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <b-form @submit.prevent="onSearch(filterName)">
      <b-row>
        <div class="col-md-4">
          <base-select
            name="Compagnia"
            label="Compagnia"
            :options="companies"
            v-model="filter.byInsurer.id"
          />
        </div>
        <div class="col-md-4">
          <base-input
            name="Polizza"
            type="text"
            label="Numero Polizza"
            v-model="filter.byInsurancePolicy.number"
            placeholder="Inserisci un numero polizza"
          />
        </div>
      </b-row>
      <b-row>
        <div class="form-group col-md-6 align-self-end">
          <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
          <b-button
            class="btn-reset"
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onClearFilter(filterName)"
            >Reset</b-button
          >
        </div>
      </b-row>
    </b-form>

    <insurance-ancillaries
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: [],
      }"
      noInnerWidth
      noActions
      includeRowSelector
      noInputPaginator
      @rowSelector="onModalSelector"
    >
    </insurance-ancillaries>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
// import InsuranceAncillaries from "@/components/tables/InsuranceAncillaries";
import { mapActions, mapGetters } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterQuickSearchAncillary",
      repository: "insurance_ancillary",
      resource: "insurance_ancillaries",
      tableRef: "QuickSearchAncillaryTableRef",
      companies: [],
      titoli: [
        { value: "0", text: "Da incassare" },
        { value: "1", text: "Incassato" },
        { value: "2", text: "Stornati" },
        { value: "3", text: "Annullati manualmente" },
        { value: "4", text: "Annullati manualmente + annullo polizza" },
        { value: "5", text: "Annullati su piattaforma compagnia" },
        { value: "6", text: "Annullati per sostituzione" },
      ],
      tipo_titoli: [
        { value: "P", text: "Polizze" },
        { value: "Q", text: "Quietanze" },
        { value: "A", text: "Appendici" },
        // { value: "X", text: "Pregresso" },
      ],
      fields: [
        {
          key: "rowSelector2",
          label: "Azioni",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "formatted_title",
          label: "Descrizione",
        },
        {
          key: "insurance_policy.number",
          label: "Polizza",
        },
      ],
    };
  },
  components: {
    InsuranceAncillaries: () =>
      import("@/components/tables/InsuranceAncillaries"),
    // InsuranceAncillaries,
    BaseInput,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          status_ancillary: "0",
          code: "P",
        },
        byInsurancePolicy: {
          number: null,
        },
        byInsurer: {
          id: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
    onModalSelector(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.companies = this.getInsurers();
  },
};
</script>
