<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <!-- <b-icon :icon="selectedEvent.icon" v-if="selectedEvent.icon"></b-icon> -->
        <!-- <span>{{ selectInfo.title }}</span> <span>[ id: {{ selectInfo.id }}]</span> -->
        <b-icon :icon="titleIcon" aria-hidden="true"></b-icon>
        <span>{{ titleLabel }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>

    <!-- <template #modal-footer="{ cancel }">
      <b-button @click="cancel()" variant="outline-secondary" size="sm"
        >CHIUDI</b-button
      >
    </template> -->

    <div class="mt-2">
      <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
        <b-form @submit.prevent="">
          <b-card class="filter">
            <b-row v-if="inputVal.payment_type === 4">
              <!-- quick search titolo -->
              <div class="form-group col-md-6">
                <div>
                  <b-button-toolbar>
                    <b-button-group>
                      <base-input
                        name="Titolo"
                        label="Titolo"
                        v-model="inpt_label_ancillary"
                        :readonly="true"
                      />
                      <b-button
                        class="mt-4 btn-quick"
                        size="sm"
                        text="Button"
                        variant="lisaweb"
                        title="Ricerca Veloce"
                        @click="openQuickSearchAncillary"
                        ><b-icon icon="search"></b-icon
                      ></b-button>
                      <b-button
                        class="mt-4 btn-quick ml-1"
                        size="sm"
                        text="Button"
                        variant="lisaweb"
                        @click="resetAncillaryId"
                        title="Elimina Titolo"
                        ><b-icon icon="trash"></b-icon
                      ></b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>
              <quick-search-ancillary-modal
                ref="quickSearchAncillary"
                @input="handleAncillaryModalInput"
              ></quick-search-ancillary-modal>
              <div class="form-group col-md-6">
                <span
                  v-if="insurance_ancillary_data"
                  class="info"
                  v-html="
                    toInfoData(
                      insurance_ancillary_data,
                      'insurance_ancillary',
                      0
                    )
                  "
                >
                </span>
              </div>
            </b-row>
            <b-row>
              <div class="col-md-6" v-if="inputVal.payment_type === 2">
                <div>
                  <!-- :label="beFormCreate.insurer.label"
                    :rules="getRules('insurer_id', 'insurance_payment')" -->
                  <base-select
                    vid="insurer"
                    label="Compagnia"
                    name="Compagnia"
                    v-model="inputVal.insurer_id"
                    :options="companies"
                    :multiple="true"
                    :taggable="true"
                    :closeOnSelect="false"
                    :rules="{ required: true }"
                  />
                  <!-- <b-button
                    type="button"
                    variant="light"
                    size="sm"
                    @click="selectAllInsurers()"
                    >Seleziona tutti</b-button
                  >

                  <b-button
                    type="button"
                    variant="light"
                    size="sm"
                    @click="deselectAllInsurers()"
                    >Deseleziona tutti</b-button
                  > -->
                  <b-button
                    :pressed.sync="myToggle"
                    variant="light"
                    @click="
                      myToggle ? selectAllInsurers() : deselectAllInsurers()
                    "
                    >{{
                      myToggle ? "Deseleziona tutti" : "Seleziona tutti"
                    }}</b-button
                  >
                </div>
              </div>

              <div class="col-md-6">
                <base-month-picker
                  :id="inputVal.payment_type"
                  vid="periodo_da"
                  name="periodo_da"
                  :label="
                    [0, 1, 3].includes(inputVal.payment_type)
                      ? 'Periodo di quietanzamento'
                      : 'Mese'
                  "
                  v-model="inputVal.from"
                />
              </div>
              <div
                class="col-md-6"
                v-if="[0, 1, 3].includes(inputVal.payment_type)"
              >
                <base-month-picker
                  vid="periodo_a"
                  name="periodo_a"
                  label="Escludi antecedenti al"
                  v-model="inputVal.to"
                  :clearable="true"
                />
              </div>
            </b-row>
            <!-- <div class="py-2 text-right"> -->
            <b-button
              @click="handleSubmit(onSubmit)"
              type="button"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="mt-2 ml-4 float-right"
            >
              {{
                [0, 1, 3].includes(inputVal.payment_type) ? "Aggiungi" : "Salva"
              }}
            </b-button>
            <b-button
              v-if="[0, 1, 3].includes(inputVal.payment_type)"
              @click="handleSubmit(onDestroy)"
              type="button"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="mt-2 float-right"
            >
              Elimina
            </b-button>
            <!-- </div> -->
          </b-card>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
// import BaseInput from "@/components/form/BaseInput";
// import BaseDatepicker from "@/components/form/BaseDatepicker";
import QuickSearchAncillaryModal from "@/components/modals/quickSearchAncillary";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import { toInfoData } from "@/utils/transforms";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  data() {
    return {
      myToggle: true,
      inpt_label_ancillary: null,
      insurance_ancillary_data: null,
      quick_value: null,
      companies: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "createInsurancePaymentOptionsModal",
    },
    value: null,
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseInput,
    BaseSelect,
    // BaseDatepicker,
    BaseMonthPicker,
    QuickSearchAncillaryModal,
  },
  methods: {
    toInfoData,
    onSubmit() {
      this.$emit("create");
    },
    onDestroy() {
      const payload = {
        at: this.inputVal.from ? this.inputVal.from : moment(),
        payment_type: this.inputVal.payment_type,
      };
      this.$emit("destroy", payload);
    },
    selectAllInsurers() {
      this.inputVal.insurer_id = this.companies.map((c) => c.value);
    },
    deselectAllInsurers() {
      this.inputVal.insurer_id = [];
    },
    resetAncillaryId() {
      this.insurance_ancillary_data = null;
      this.inpt_label_ancillary = null;
      this.inputVal.insurance_ancillary = null;
    },
    handleAncillaryModalInput(value) {
      this.quick_value = value.id;
      // this.filter.byAttribute.id = value.id;
      this.inputVal.insurance_ancillary = value.id;
      this.inpt_label_ancillary = value.title;
      this.insurance_ancillary_data = value;
      this.$bvModal.hide("quickSearchAncillaryModal");
    },
    openQuickSearchAncillary() {
      this.quick_value = null;
      this.$bvModal.show("quickSearchAncillaryModal");
    },
    close() {
      this.inputVal.insurance_ancillary = null;
      this.inpt_label_ancillary = null;
      this.insurance_ancillary_data = null;
      this.myToggle = true;
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    titleLabel() {
      let text = "";
      switch (this.inputVal.payment_type) {
        case 0:
          text = "Crea - Includi Sospesi";
          break;
        case 1:
          text = "Crea - Includi Arretrati";
          break;
        case 2:
          text = "Crea - Quietanze";
          break;
        case 3:
          text = "Crea - Includi Acconti";
          break;
        case 4:
          text = "Crea - Manuale";
          break;
        default:
          break;
      }
      return text;
    },
    titleIcon() {
      let icon = "receipt";
      switch (this.inputVal.payment_type) {
        case 0:
          icon = "bookmark-plus";
          break;
        case 1:
          icon = "calendar2-plus";
          break;
        case 2:
          icon = "calendar2-event";
          break;
        case 3:
          icon = "cash-stack";
          break;
        case 4:
          icon = "receipt";
          break;
        default:
          break;
      }
      return icon;
    },
  },
  mounted() {
    this.companies = this.getInsurers().filter(
      (company) => !company.importer_id
    );
  },
};
</script>
<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: auto; unset or auto value is not ok */
  overflow-y: visible;
}
</style>
