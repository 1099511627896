<template>
  <div class="mx-1">
    <insurance-payments
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{ byAttribute: { status_registry: 0 } }"
      :filterName="filterName"
      :hasChecksButtonGroup="false"
      :ref="tableRef"
    ></insurance-payments>
  </div>
</template>

<script>
import InsurancePayments from "@/components/tables/InsurancePayments";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      tableRef: "InsurancePaymentsTableRef",
      filterName: "filterInsurancePayments",
      resource: "insurance_payments",
      repository: "insurance_payment",
      fields: [
        {
          key: "id",
          label: "ID",
          // stickyColumn: true,
          sortable: true,
        },
      ],
    };
  },
  components: {
    InsurancePayments,
  },
  methods: {
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onDestroy(id) {
      // call Repo.destroy
      // console.debug("DESTROYING id:", id);
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          // console.debug("successfully deleted!");
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
};
</script>
