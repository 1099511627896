<template>
  <div class="mt-1 mx-3">
    <deadlines>
    </deadlines>
  </div>
</template>

<script>
import Deadlines from '@/components/insurance-payments/deadlines/index.vue'
import { mapActions } from 'vuex';

export default {
  props: {
  },
  components: {
    Deadlines
  },
  methods:{
   ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({ text: "Verifica scadenze per compagnia", path: "module.QUIE.VSCA", level: 1 });
  }
}
</script>