<template>
  <div class="mt-1">
    <b-card header="true" header-tag="header" class="mt-2">
      <div
        slot="header"
        class="d-flex justify-content-between align-items-center"
      >
        <p class="mb-0 d-inline-block align-middle">
          <b>Informazioni</b>
        </p>
      </div>
      <b-row v-for="(field, index) in fields" :key="index">
        <div class="col-md-4 border-bottom">
          {{ field.label.toUpperCase() }}
        </div>
        <div class="col-md-8 border-bottom">
          {{ renderDbRowData(field, dbRowData) }}
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { renderDbRowData } from "@/utils/payments";

export default {
  mixins: [FormMixin],
  name: "General",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      details: null,
      repository: "insurance_payment",
      allRepositories: [],
      isLoading: true,
      defaultLabels: {
        insurance_payment: {},
      },
      fields: [
        {
          label: this.getDictionary("insurer"),
          value: "compagnia",
        },
        {
          label: this.getDictionary("insurance_policy"),
          value: "number",
        },
        {
          label: this.getDictionary("expired_at"),
          value: "expired_at",
        },
        {
          label: this.getDictionary("contractor"),
          value: "contraente",
        },
        {
          label: this.getDictionary("status_payment", "insurance_payment"),
          option: "status_payment",
          value: "status_payment.text",
        },
        {
          label: this.getDictionary("payment_type", "insurance_payment"),
          option: "payment_type",
          value: "payment_type.text",
        },
        {
          label: this.getDictionary("gross", "insurance_payment"),
          value: "gross",
        },
        {
          label: this.getDictionary("comment", "insurance_payment"),
          value: "comment",
        },
      ],
    };
  },
  methods: {
    renderDbRowData,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-md-4.border-bottom {
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
