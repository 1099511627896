<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Consolidated from "@/components/insurance-payments/consolidation/Consolidated.vue";
import NotConsolidated from "@/components/insurance-payments/consolidation/NotConsolidated.vue";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.QUIE",
      tabs: [
        { name: "Consolidated", text: "Quietanzamenti consolidati" },
        { name: "NotConsolidated", text: "Quietanzamenti NON consolidati" },
      ],
      tabsGroup: "Consolidation",
      bcs: [
        {
          text: "Quietanzamento",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Consolidature",
          path: "",
          level: 1,
        },
        {
          text: "Quietanzamenti consolidati",
          path: "module.QUIE.CONS",
          tab: `#${this.selected}`,
          level: 2,
        },
      ],
      maxLevel: 2, // use to identify the tabs level
      selected: "Consolidated",
      tabIndex: 0,
      // isLoading: false,
    };
  },
  components: {
    Consolidated,
    NotConsolidated,
  },
};
</script>
