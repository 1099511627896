<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].net.label"
                    vid="net"
                    :label="beForm[rep].net.label"
                    v-model="form[rep].net"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                    :rules="getRules('net')"
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].tax.label"
                    vid="tax"
                    :label="beForm[rep].tax.label"
                    v-model="form[rep].tax"
                    :rules="getRules('tax')"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].gross.label"
                    vid="gross"
                    :label="beForm[rep].gross.label"
                    v-model="grss"
                    :rules="getRules('gross')"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                    readonly
                  />
                </div>
              </b-row>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid || beForm[rep].status_payment.value === 3"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseCurrency from "@/components/form/BaseCurrency";
// import { mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
// import { toLocaleCurrency } from "@/utils/strings";
export default {
  name: "Premio",
  mixins: [FormMixin, ShortcutMixin],
  components: {
    // BaseSelect,
    // BaseInput,
    // BaseRadio,
    // BaseDatepicker,
    // BaseDynamicInput,
    BaseIcon,
    BaseCurrency,
  },
  data() {
    return {
      repository: "insurance_payment",
      form: {
        insurance_payment: {
          gross: null,
          net: null,
          tax: null,
          modified: null,
        },
      },
    };
  },
  props: {
    resourceId: Number,
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.resourceId)
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    initDefaultFormValues() {},
    onSubmit() {
      this.isLoading = true;
      //Update gross value
      this.form[this.repository].gross = this.grss;
      this.update(this.repository, this.resourceId)
        .then(() => {
          // fetch table data
          this.$emit("fetch");
          this.$parent.$emit("fetch");
          this.$bvModal.hide("modal");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
    grss() {
      return this.form[this.repository].net + this.form[this.repository].tax;
    },
  },
};
</script>
