<template>
  <div class="mx-1">
    <div class="mt-2">
      <b-form @submit.prevent="">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="Mese"
                label="Mese"
                :options="months"
                v-model="filter.mese"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Anno"
                label="Anno"
                :options="years"
                v-model="filter.anno"
              />
            </div>

            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="button"
                variant="lisaweb"
                size="sm"
                class="btn-verify"
                @click="verifica()"
                >Verifica</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";

export default {
  data() {
    return {
      filter: this.initFilter(),
      months: [
        { value: "0", text: "Gennaio" },
        { value: "1", text: "Febbraio" },
        { value: "2", text: "Marzo" },
        { value: "3", text: "Aprile" },
        { value: "4", text: "Maggio" },
        { value: "5", text: "Giugno" },
        { value: "6", text: "Luglio" },
        { value: "7", text: "Agosto" },
        { value: "8", text: "Settembre" },
        { value: "9", text: "Ottobre" },
        { value: "10", text: "Novembre" },
        { value: "11", text: "Diecembre" },
      ],
      years: [
        { value: "0", text: "2016" },
        { value: "1", text: "2017" },
        { value: "2", text: "2018" },
        { value: "3", text: "2019" },
        { value: "4", text: "2020" },
        { value: "5", text: "2021" },
        { value: "6", text: "2022" },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          mese: null,
          anno: null,
        },
      };
      return init;
    },
    verifica() {
      console.debug("verifica() ???");
    },
  },
  components: {
    BaseSelect,
  },
};
</script>

<style scoped>
.form-group.col-md-3.align-self-end {
  margin-bottom: 11px !important;
}
</style>
