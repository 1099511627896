<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-4" v-if="!isLoading">
                  <base-radio
                    :name="beForm[rep].to_collect.label"
                    vid="to_collect"
                    :label="beForm[rep].to_collect.label"
                    v-model="form[rep].to_collect"
                    :options="beForm[rep].to_collect.options"
                  />
                </div>
              </b-row>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseRadio from "@/components/form/BaseRadio";

export default {
  name: "Premio",
  mixins: [FormMixin, ShortcutMixin],
  components: { BaseRadio, BaseIcon },
  data() {
    return {
      repository: "insurance_payment",
      form: {
        insurance_payment: {
          to_collect: null,
        },
      },
    };
  },
  props: {
    resourceId: Number,
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.resourceId)
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.resourceId)
        .then(() => {
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
