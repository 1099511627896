<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].net.label"
                    vid="net"
                    :label="beForm[rep].net.label"
                    v-model="form[rep].net"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                    :rules="getRules('net')"
                    readonly
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].tax.label"
                    vid="tax"
                    :label="beForm[rep].tax.label"
                    v-model="form[rep].tax"
                    :rules="getRules('tax')"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                    readonly
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-currency
                    :name="beForm[rep].gross.label"
                    vid="gross"
                    :label="beForm[rep].gross.label"
                    v-model="form[rep].gross"
                    :rules="getRules('gross')"
                    :options="{ currency: 'EUR', locale: 'it-IT' }"
                    readonly
                  />
                </div>
              </b-row>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
// import BaseSelect from "@/components/form/BaseSelect";
// import BaseInput from "@/components/form/BaseInput";
// import BaseRadio from "@/components/form/BaseRadio";
// import BaseDatepicker from "@/components/form/BaseDatepicker";
// import BaseDynamicInput from "@/components/form/BaseDynamicInput";
import BaseCurrency from "@/components/form/BaseCurrency";
// import { mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";

export default {
  mixins: [FormMixin, ShortcutMixin],
  components: {
    // BaseSelect,
    // BaseInput,
    // BaseRadio,
    // BaseDatepicker,
    // BaseDynamicInput,
    BaseIcon,
    BaseCurrency,
  },
  data() {
    return {
      repository: "insurance_payment",
      id: this.$route.params.id,
      form: {
        insurance_payment: {
          gross: null,
          net: null,
          tax: null,
        },
      },
    };
  },

  created() {
    this.isLoading = true;
    // const cr = this.fetchEditForm(this.repository, this.id);
    // Promise.all([cr]).then(() => {
    //   // this.initDefaultFormValues();
    //   this.isLoading = false;
    // });
    this.fetchEditForm(this.repository, this.id)
      .then(() => {
        // this.initDefaultFormValues();
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  beforeMount() {
    // console.debug("beforeMount");
    // this.optBornCountries = this.countries();
    // this.form[this.rep].attribute_NOB_value = this.optBornCountries.find(
    //   (c) => c.text == this.defaultCountryName
    // ).value;
    // this.optBornProvinces = this.provinces()(
    //   this.form[this.rep].attribute_NOB_value
    // );
  },
  methods: {
    initDefaultFormValues() {},
    onSubmit() {
      //Add overlay during edit operation
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.shortcut(
            "module.QUIE.RICQ",
            null,
            null,
            "filterInsurancePayments"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // ...mapGetters("auth", [
    //   "countries",
    //   "provinces",
    //   "councils",
    //   "provinceByCouncil",
    //   "countryByProvince",
    // ]),
  },
  computed: {
    rep() {
      return this.repository;
    },
    // ...mapGetters("auth", ["roles"]),
  },
};
</script>
